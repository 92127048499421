import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [3, 4, 5],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi! 
    </Text>
    <p>{`I'm Ismail, a software engineer from Paris.`}<br parentName="p"></br>{`
`}{`I use this website as a way to document whatever I'm working on in my free time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      